<template>
  <v-menu
    content-class="menu-arrow"
    transition="slide-x-transition"
    :close-on-content-click="false"
    bottom
    right
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        icon
        v-bind="attrs"
        v-on="on"
        class="button-primary"
        elevation="0"
        width="40px !important"
        height="40px !important"
      >
        <v-icon
          color="#000000"
          size="18px"
        >
          mdi-tune
        </v-icon>
      </v-btn>
    </template>

    <div class="content-menu">
      <div class="display-flex content-wrap">
        <div class="width-four-div content-1">
          <p class="text-title mon-bold">{{ texts.disputes.filters.textLabelStatus }}</p>
          <div
            class="display-flex align-items-center"
            v-for="(item, index) in statusValues"
            :key="index"
          >
            <v-checkbox
              v-model="disputeStatus"
              :value="item.value"
            />
            <p class="text-values mon-regular">{{ item.name }}</p>
          </div>
        </div>
        <div class="width-four-div content-2">
          <p class="text-title mon-bold">{{ texts.disputes.filters.textLabelStatusPaid }}</p>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="paymentStatusPaid"
              @change="changeStatusPaid"
            />
            <p class="text-values mon-regular">{{ texts.disputes.filters.paymentStatusPaid }}</p>
          </div>
          <div class="display-flex align-items-center">
            <v-checkbox
              v-model="paymentStatusToPaid"
              @change="changeStatusToPaid"
            />
            <p class="text-values mon-regular">{{ texts.disputes.filters.paymentStatusToPaid }}</p>
          </div>
        </div>
      </div>
    </div>
  </v-menu>
</template>

<script>
export default {
  name: "DisputesFilterLayout",
  props: {
    texts: {
      type: Object,
    },
    tab: {
      type: Number,
    },
  },
  data() {
    return {
      //VARIABLES
      statusValues: [],
      disputeStatus: [],
      paymentStatusPaid: null,
      paymentStatusToPaid: null,
      disputePaymentStatus: null,
    };
  },
  beforeMount() {
    if (this.tab == 0) {
      this.statusValues = [
        {
          value: "ca16f9be-c88c-431e-a053-625f205a671d",
          name: "Iniciada",
        },
        {
          value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
          name: "Analizando",
        },
        {
          value: "69b41776-e327-4d72-8132-1428d0c57631",
          name: "Inspección programada",
        },
      ];
    } else {
      this.statusValues = [
        {
          value: "db2289ae-6222-4785-9587-0276e0c21657",
          name: "Resuelta",
        },
        {
          value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
          name: "Cancelada",
        },
      ];
    }
  },
  methods: {
    changeStatusPaid: function (e) {
      this.paymentStatusToPaid = null;

      if (this.paymentStatusPaid == true) {
        this.disputePaymentStatus = true;
      } else {
        this.disputePaymentStatus = null;
      }
    },
    changeStatusToPaid: function () {
      this.paymentStatusPaid = null;

      if (this.paymentStatusToPaid == true) {
        this.disputePaymentStatus = false;
      } else {
        this.disputePaymentStatus = null;
      }
    },
  },
  computed: {},
  watch: {
    disputeStatus: function () {
      this.$store.commit("setDisputeStatus", this.disputeStatus);
    },
    disputePaymentStatus: function () {
      this.$store.commit("setDisputePaymentStatus", this.disputePaymentStatus);
    },
    tab: function () {
      this.disputeStatus = [];
      this.$store.commit("setDisputeStatus", this.disputeStatus);
      this.$store.commit("setDisputePaymentStatus", null);
      if (this.tab == 0) {
        this.statusValues = [
          {
            value: "ca16f9be-c88c-431e-a053-625f205a671d",
            name: "Iniciada",
          },
          {
            value: "a35626a4-c14b-4f3f-85d0-beb4f60c25cb",
            name: "Analizando",
          },
          {
            value: "69b41776-e327-4d72-8132-1428d0c57631",
            name: "Inspección programada",
          },
        ];
      } else {
        this.statusValues = [
          {
            value: "db2289ae-6222-4785-9587-0276e0c21657",
            name: "Resuelta",
          },
          {
            value: "44687fe1-51d9-48d8-8b7c-a9ad2a9819a7",
            name: "Cancelada",
          },
        ];
      }
    },
  },
};
</script>

<style>
.width-four-div .v-icon.v-icon {
  font-size: 14px;
}

.width-four-div .v-input--selection-controls__ripple {
  height: 20px;
  width: 20px;
  left: -5px;
  top: calc(50% - 18px);
}

.width-four-div .v-application--is-ltr .v-input--selection-controls__input {
  margin-right: 0px;
}

.width-four-div .v-input--selection-controls {
  margin-top: 0px;
}

.width-four-div .v-messages {
  display: none;
}

.width-four-div .v-input__slot {
  margin-bottom: 0px;
}

.width-four-div .v-input--selection-controls__input {
  margin-right: 0px !important;
}
</style>

<style scoped>
.text-values {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: -3px;
}

.text-title {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.content-1 {
  width: 70%;
}

.content-2 {
  width: 30%;
}

.tab-content-menu-fit {
  width: fit-content;
  background-color: #ffffff;
  padding: 20px;
}

.content-menu {
  width: 450px;
  background-color: #ffffff;
  padding: 20px;
}

.menu-arrow {
  margin-top: 50px;
  contain: initial;
  overflow: visible;
}

.button-filter {
  width: 40px !important;
  min-width: 40px !important;
  height: 40px !important;
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0%
    0% no-repeat padding-box;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  opacity: 1;
}

@media (max-width: 960px) {
  .content-menu {
    width: 470px;
  }

  .content-3,
  .content-4 {
    margin-top: 10px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 50%;
  }

  .content-wrap {
    flex-wrap: wrap;
  }

  .remove-flex {
    display: block;
  }

  .remove-ml {
    margin-left: 0px !important;
  }
}

@media (max-width: 600px) {
  .content-menu {
    width: 200px;
  }

  .content-1,
  .content-2,
  .content-3,
  .content-4 {
    width: 100%;
    margin-top: 10px;
  }

  .content-wrap {
    flex-wrap: wrap;
  }
}
</style>